/*eslint-disable*/
import React from "react";
import { BsTwitter } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://github.com/pranavrai070" target="blank">
      <FaGithub />
    </a>
    <a href="https://www.linkedin.com/in/pranavrai070/" target="blank">
      <FaLinkedin />
    </a>
    <a href="https://twitter.com/pranavrai070" target="blank">
      <BsTwitter />
    </a>
  </div>
);

export default SocialMedia;
